.home {
  padding-top: 90px;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .profile-image {
    height: auto;
    max-width: 480px;
    width: 100%;
    margin-top: 3rem;
    border-radius: 50%;
    animation: fadeIn 1s;
    transition: ease-in-out 0.3s;
    box-shadow: 0px 5px 10px 3px #908d84;
  }

  .bio {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem;
    animation: fadeIn 1s;
    transition: ease-in-out 0.3s;

    h1 {
      font-size: 56px;
      font-weight: bolder;
      color: #304149;
      text-transform: uppercase;
      margin-bottom: 1rem;
      transition: ease-in-out 0.3s;
    }

    .bio-text {
      width: 100%;
      font-family: "Source Sans Pro", sans-serif;
      color: #304149;
      font-size: 18px;
      line-height: 32px;
      transition: ease-in-out 0.3s;
      a {
        font-family: "Source Sans Pro", sans-serif;
        text-decoration: underline;
        color: #344b67;
        transition: ease-in-out 0.2s;
        &:hover {
          color: #2e435c;
          text-shadow: 1px 1px 1px #b4b1a6;
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1600px) {
  .home .bio {
    max-width: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .home {
    .profile-image {
      max-width: 420px;
    }
    .bio {
      max-width: 500px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .home {
    .bio {
      align-items: center;
      max-width: 700px;
    }
    .profile-image {
      max-width: 350px;
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    .bio {
      max-width: 500px;
      h1 {
        font-size: 42px;
      }
    }
    .profile-image {
      max-width: 250px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 540px) {
  .home {
    .bio {
      max-width: 360px;
      h1 {
        font-size: 33px;
      }
      .bio-text {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .profile-image {
      max-width: 250px;
    }
  }
}

@media screen and (max-width: 375px) {
  .home {
    .bio {
      margin-bottom: 3rem;
      max-width: 300px;
      .bio-text {
        line-height: 24px;
      }
    }
    .profile-image {
      max-width: 180px;
    }
  }
}

@media screen and (max-width: 320px) {
  .home {
    .bio {
      max-width: 280px;
    }
    .profile-image {
      max-width: 140px;
    }
  }
}

@media screen and (max-width: 280px) {
  .home {
    .bio {
      max-width: 250px;
    }
    .profile-image {
      max-width: 140px;
    }
  }
}
