.projects {
  width: 100%;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s;
  transition: ease-in-out 0.3s;

  h1 {
    font-size: 48px;
    letter-spacing: 2px;
    color: #304149;
    margin-top: 3rem;
    margin-bottom: 3rem;
    transition: ease-in-out 0.3s;
  }

  .projects-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .divider {
    width: 80%;
    height: 1px;
    margin: 2rem;
    background-color: #8a9398;
    border-width: 0;
    border-style: none;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

@media screen and (max-width: 1700px) {
  .projects h1 {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .projects h1 {
    font-size: 42px;
  }
}

@media screen and (max-width: 540px) {
  .projects h1 {
    font-size: 38px;
  }
}
