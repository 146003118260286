.contacts {
  width: 100%;
  height: 100vh;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #59666d;
  font-size: 24px;
  animation: fadeIn 1s;

  .contact-container {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
  }

  a {
    color: inherit;
  }

  h1 {
    color: #304149;
    font-size: 48px;
    letter-spacing: 2px;
    margin-bottom: 1rem;
  }

  h3 {
    margin: 1.5rem;
    transition: ease-in-out 0.3s;
    &:hover {
      color: #304149;
      text-shadow: 2px 2px 1px #b4b1a6;
    }
  }

  .socials {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size: 40px;
  }

  .fa-envelope {
    margin-right: 1rem;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .contacts {
    font-size: 18px;
    .socials {
      font-size: 28px;
    }
    h1 {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 380px) {
  .contacts {
    font-size: 15px;
    .socials {
      font-size: 24px;
    }
    h1 {
      font-size: 35px;
    }
  }
}
