nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90px;
  background-color: #1e3857;
  background-image: url("https://www.transparenttextures.com/patterns/egg-shell.png");
  z-index: 1;
  top: 0;
  width: 100%;
  position: fixed;

  .logo {
    color: #d9dcdf;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 32px;
    transition: ease-in-out 0.3s;
    &:hover {
      color: #8e9bab;
    }
    a {
      color: inherit;
    }
  }

  .nav-links {
    display: flex;
    justify-content: space-around;
    z-index: 1;
    transition: ease-in-out 0.3s;
    width: 40%;
    a {
      color: #d9dcdf;
      letter-spacing: 3px;
      font-weight: bold;
      font-size: 18px;
      padding: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 6px;
      transition: ease-in-out 0.3s;
      &:hover {
        background-color: rgba(92, 111, 133, 0.4);
        color: #ffffff;
      }
    }
  }

  .burger {
    display: none;
    cursor: pointer;
    div {
      width: 25px;
      height: 3px;
      margin: 5px;
      background-color: #d9dcdf;
      transition: all 0.3s ease;
    }
  }

  @media screen and (max-width: 1024px) {
    .logo {
      font-size: 24px;
    }
    .nav-links {
      width: 50%;
    }
    #cursor {
      font-size: 21px;
    }
  }

  @media screen and (max-width: 768px) {
    .nav-links {
      position: fixed;
      right: 0;
      height: 100vh;
      top: 90px;
      background-color: #1e3857;
      background-image: url("../assets/egg-shell.png");
      background-position: 0 0;
      background-attachment: fixed;
      flex-direction: column;
      align-items: center;
      width: 50%;
      display: none;
      padding-bottom: 50vh;
      justify-content: space-evenly;
      li {
        animation: navLinkFade 0.6s;
      }
    }
    .burger {
      display: block;
    }
  }

  @media screen and (max-width: 540px) {
    .nav-links {
      width: 100%;
      padding-bottom: 30vh;
    }
  }

  @media screen and (max-width: 420px) {
    .logo {
      font-size: 21px;
      .shorten-name {
        display: none;
      }
    }
    .burger {
      transform: scale(0.9);
    }
  }

  @media screen and (max-width: 360px) {
    .logo {
      font-size: 18px;
    }
  }

  .nav-active {
    display: flex;
  }

  @keyframes navLinkFade {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .toggle {
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }
}
